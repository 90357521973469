<template>
  <div>
    <chart :options="options" constructor-type="stockChart" />
  </div>
</template>

<script>
import yaml from 'js-yaml'
import cloneDeep from 'lodash.clonedeep'
import { Chart } from 'highcharts-vue'
import { ONE_DAY } from '@/common/constants.js'
import options from './options.js'

const POINTS_URL = 'https://tool.cryp-trades.com/api/v1/markets/points'
const PRICES_URL =
  'https://tool.cryp-trades.com/api/v1/markets/prices/v2/public/index-price-kline'

export default {
  name: 'market-points-chart',

  components: {
    Chart,
  },

  data: () => ({
    points: [],
    options: null,
  }),

  async created() {
    this.options = cloneDeep(options)
    this.fetch()
  },

  methods: {
    async fetch() {
      const ymlData = await this.$http.get(POINTS_URL)
      const points = yaml.load(ymlData)
      this.points = points.slice(points.length / 2)
      this.options.series[0].data = this.points
      await this.fetchPrices()
    },
    async fetchPrices() {
      let from = parseInt((Date.now() - ONE_DAY * 30) / 1e3)
      const to = this.points[this.points.length - 1][0] / 1e3
      while (from < to) {
        const params = {
          symbol: 'BTCUSD',
          interval: 5,
          from,
        }
        const prices = await this.$http.get(PRICES_URL, { params })

        prices.forEach((price) => {
          this.options.series[1].data.push([
            price.open_time * 1e3,
            parseFloat(price.close),
          ])
          from = price.open_time
        })
      }
    },
  },
}
</script>
