<template>
  <div class="flex flex-col gap-4">
    <div>
      <div class="form-control flex flex-col">
        <label class="cursor-pointer label">
          <span class="label-text ml-auto mr-2">Show chart</span>
          <input
            type="checkbox"
            checked="checked"
            class="toggle"
            v-model="show"
          />
        </label>
      </div>

      <market-points-chart class="w-full" v-if="show" />
    </div>
    <market-points-table />
  </div>
</template>

<script>
import MarketPointsTable from './table/index.vue'
import MarketPointsChart from './chart/index.vue'

export default {
  name: 'market-points',

  meta: {
    title: 'Market Points',
  },

  data: () => ({
    // TODO (nakhoa): refactor this with atoms toggle
    show: false,
  }),

  components: {
    MarketPointsTable,
    MarketPointsChart,
  },
}
</script>
