import Vue from 'vue/dist/vue.js'
import { headerItem } from '@/utils/table'

export default function headers () {
  return [
    headerItem('項目目', '項目目', {
      cellClass: 'font-bold sticky left-0',
      generator: (item) => {
        return { value: item.name }
      },
    }),
    headerItem('点数', '点数', {
      generator: (item) => {
        return { value: item.point }
      },
    }),
    headerItem('計算方法', '計算方法', {
      generator: (item) => {
        return {
          component: Vue.component('text-tag', {
            props: ['text'],
            template: '<div v-html="text" />',
          }),
          props: { text: item.method },
        }
      },
    }),
    headerItem('数値範囲', '数値範囲', {
      generator: (item) => {
        return { value: item.scope }
      },
    }),
  ]
}
