<template>
  <div>
    <d-table :headers="headers" :items="items" />
  </div>
</template>

<script>
import items from './items.js'
import headerGenerator from './headers.js'

const URL = 'https://tool.cryp-trades.com/api/v1/markets/market-points'

export default {
  name: 'market-points-table',

  meta: {
    title: 'Market Points',
  },

  data: () => ({
    items,
    headers: headerGenerator(),
  }),

  created() {
    this.fetch()
  },

  methods: {
    async fetch() {
      const data = await this.$http.get(URL)
      this.items = this.items.map((item) => {
        item.point = data[item.key] || item.point
        return item
      })
    },
  },
}
</script>
